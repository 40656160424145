.container {
    height: 100vh;
    background: url("https://app-dev.bedaya.eu/static/media/bedaya-landing-bg.30a11b53.svg");
}

.btn-logo {
    width: 20px;
}

Card {
    position: absolute;
    top: 50%;
    left: 50%;
}
